/*-----------------------------------------------------------------------------------

Global Styles

-----------------------------------------------------------------------------------*/

.clear {
	clear: both;
	display: block;
	font-size: 0px;
	height: 0px;
	line-height: 0;
	width: 100%;
	overflow:hidden;
}


img { max-width: 100%; }

iframe { border: none !important; }


#wrapper {
	position: relative;
	float: none;
	//width: 1220px;
	background-color: #FFF;
	margin:0 auto;
}

@media (min-width: $screen-lg-min){
	#wrapper {
		width: 1220px;
		@include box-shadow(0 0 10px rgba(0,0,0,0.1));
	}
}


/* ----------------------------------------------------------------
	Stretched Layout
-----------------------------------------------------------------*/

.stretched {
	#wrapper {
		width: 100%;
		margin: 0;
		box-shadow: none;
	}
}


/*-----------------------------------------------------------------------------------

Titles

-----------------------------------------------------------------------------------*/


/* ----------------------------------------------------------------
	Page Title
-----------------------------------------------------------------*/

/*
#page-title {
	position: relative;
	padding: $page-title-padding 0;
	background-color: $page-title-bg;
	border-bottom: 1px solid #EEE;

	.container {
		position: relative;
	}

	h1 {
		padding: 0;
		margin: 0;
		line-height: 1;
		font-weight: 600;
		letter-spacing: 1px;
		color: #333;
		font-size: $page-title-size;
		text-transform: uppercase;
	}

	span {
		display: block;
		margin-top: 10px;
		font-weight: 300;
		color: #777;
		font-size: $page-title-subtitle-size;
	}
}*/

/*
.breadcrumb {
	position: absolute !important;
	width: auto !important;
	top: 50% !important;
	left: auto !important;
	right: 15px !important;
	margin: -10px 0 0 0 !important;
	background-color: transparent !important;
	padding: 0 !important;
	font-size: 12px;
}

.breadcrumb {
	a {
		color: #555;

		&:hover {
			color: $brand-color;
		}
	}

	i {
		width: auto !important;
	}
}*/


/* ----------------------------------------------------------------
	Content
-----------------------------------------------------------------*/


#content {
	position: relative;
	overflow: hidden;
	background-color: #FFF;

	p {
		line-height: $line-height-content;
	}

	.content-wrap {
		position: relative;
		padding: $margin-big 0 $margin-small;
	}

	.container {
		position: relative;
	}
}

