/* tags to include inside de editor */

.block { // inside a content block


h2.communication-title {
  color: $brand-color;
  padding: 0px; margin: 0px;
  @extend .text-center;
  font-size: 2.5em;
}

h3.communication-subtitle {
  color: lighten($brand-color, 25%); font-size: 1.7em;
  padding: 0px; margin: -10px 0px 0px 0px;
  @extend .text-center;
}


.featured {
  width: 100%; text-align: center; @extend .list-unstyled; padding: 20px 0px 20px 0px;
  li {
    /* @extend .col-xss-12; @extend .col-xs-12; @extend .col-sm-4; */
    @extend .col-xs-12; @extend .col-sm-4;
  }

}

} // end .block
