/*-----------------------------------------------------------------------------------

Social share block

-----------------------------------------------------------------------------------*/

.section-social-share{
	padding:30px 0;
	h5{
		margin:0;
		float:left;
		color: #999;
	}
	background: #dcdcdc;

	.social-share-list{
		margin:0 auto;
		text-align:center;
		li{
			a{
				margin: 0 0 0 7px;
				line-height:1;
				height: 30px;
    			display: inline-block;
    			vertical-align:middle;
				.icon{
					font-size:27px;
					color:#a0a0a0;
					line-height:28px;
				}
				

				&:hover,
				&:focus{
					.ion-social-twitter{
						color: $twitter-color;
					}
					.ion-social-facebook{
						color: $facebook-color;
					}
					.ion-social-linkedin{
						color: $linkedin-color;
					}
					.ion-email{
						color: $secondary-color;
					}
				}
			}
			
		}
	}
}


@media (min-width: $screen-md-min) {
	.section-social-share{
		.social-share-list{
			float:right;
			margin:0;	
		}
	}
}