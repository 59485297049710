// Buttons
// --------------------------------------------------

.btn {
  position:relative;
  font-weight: normal;
  background-image: none;
  border: 1px solid transparent;
  font-family: $secondary-font-light;
  text-transform: uppercase;
  i.icon{
    color: inherit;
  }
}

.btn-icon-left{
  .icon{
    position:relative;
    top:-1px;
    padding-right:7px;
  }
}

.btn-icon-right{
  .icon{
    position:relative;
    top:-1px;
    padding-left:7px;
  }
}

.nav-next{
  float:right;
}

/*
.btn {
  position:relative;
  font-weight: normal;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  font-family: $secondary-font;
  text-transform: uppercase;
  text-overflow: ellipsis;
  overflow:hidden;
  &:hover,
  &:focus,
  &.focus {
    text-decoration: none;
  }

  &:active,
  &.active {
    outline: 0;
    background-image: none;
    @include box-shadow(inset 0 3px 5px rgba(0,0,0,.125));
  }
}

.btn-inline{
  width:auto;
}*/

// Alternate buttons
// --------------------------------------------------

.btn-blue {
  @include button-variant($btn-blue-color, $btn-blue-bg, $btn-blue-border);
}
.btn-orange {
  @include button-variant($btn-orange-color, $btn-orange-bg, $btn-orange-border);
}
.btn-green {
  @include button-variant($btn-green-color, $btn-green-bg, $btn-green-border);
}
.btn-light {
  @include button-variant($btn-light-color, $btn-light-bg, $btn-light-border);
}
.btn-dark {
  @include button-variant($btn-dark-color, $btn-dark-bg, $btn-dark-border);
}




.btn-default{
  &:focus,
  &.focus,
  &:hover {
    background-color: rgba($btn-default-bg, 0.15);
    border-color: $btn-default-border;
    color: $btn-default-border;
  }
  &:active,
  &.active{
    &:hover,
    &:focus,
    &.focus {
      background-color: rgba($btn-default-bg, 0.15);
      border-color: $btn-default-border;
      color: $btn-default-border;
    }
  }
}

.btn-md {
  @include button-size($padding-md-vertical, $padding-md-horizontal, $font-size-md - 2, $line-height-base, $btn-border-radius-base);
  .icon-chevron-right{
    position:absolute;
    right:15px;
    top:50%;
    margin-top:-10px;
  }
  .icon{
    font-size:  $font-size-md - 2;
    margin-left: 0;
  }
}

/*
.btn-default{
  &:focus,
  &.focus,
  &:hover {
    background-color: $brand-color;
    border-color: $brand-color;
    color: #fff;
  }
  &:active,
  &.active{
    &:hover,
    &:focus,
    &.focus {
      background-color: $brand-color;
      border-color: $brand-color;
      color: #fff;
    }
  }
}

.btn-inverse{
  @include button-variant($btn-default-color, $btn-default-bg, $btn-default-border);
  &:focus,
  &.focus,
  &:hover {
    background-color: $brand-color;
    border-color: #fff;
    color: #fff;
  }
  &:active,
  &.active{
    &:hover,
    &:focus,
    &.focus {
      background-color: $brand-color;
      border-color: #fff;
      color: #fff;
    }
  }
}


.btn{
  padding: 10px $padding-base-horizontal;
  .icon{
    font-size: 14px;
    margin-left: 3px;
  }
}

.btn-md {
  @include button-size($padding-md-vertical, $padding-md-horizontal, $font-size-md - 2, $line-height-base, $btn-border-radius-base);
  .icon-chevron-right{
    position:absolute;
    right:15px;
    top:50%;
    margin-top:-10px;
  }
  .icon{
    font-size:  $font-size-md - 2;
    margin-left: 0;
  }
}

.btn-lg {
  // line-height: ensure even-numbered height of button next to large input
  @include button-size($padding-large-vertical, $padding-large-horizontal, $font-size-large, $line-height-large, $btn-border-radius-large);
}
.btn-sm {
  // line-height: ensure proper height of button next to small input
  @include button-size($padding-small-vertical, $padding-small-horizontal, $font-size-small, $line-height-small, $btn-border-radius-small);
}
.btn-xs {
  @include button-size($padding-xs-vertical, $padding-xs-horizontal, $font-size-small, $line-height-small, $btn-border-radius-small);
}


.btn-lg{
  font-size:  $font-size-large - 4;
  .icon{
     margin-left: 0;
     font-size:  $font-size-large - 4;
  }
}

.btn-md.btn-icon-left{
  padding-left: 52px;
  .icon:first-child{
    position:absolute;
    left:15px;
    top:50%;
    margin-top:-10px;
    width:15px;
  }
}

.btn-lg.btn-icon-left{
  padding-left: 53px;
  .icon:first-child{
    position:absolute;
    left:15px;
    top:50%;
    font-size: 20px;
    margin-top: -12px;
    width:15px;
  }
}

.btn-md.btn-icon-right{
  padding-right: 37px;
  .icon-chevron-right{
    position:absolute;
    right:15px;
    top:50%;
    margin-top:-9px;
    width:15px;
  }
}

.btn-lg.btn-icon-right{
  padding-right: 37px;
  .icon-chevron-right{
    position:absolute;
    right:15px;
    top:50%;
    margin-top:-10px;
    width:15px;
  }
}


@media (max-width: $screen-sm-max) {
  .btn{
    width:100%;
  }
}


@media (min-width: $screen-xs-min) {
  .btn-md{
    font-size: $font-size-md;
    .icon{
      font-size:  $font-size-md;
    }
  }
  .btn-lg{
    font-size: $font-size-large;
    .icon{
      font-size:  $font-size-large;
    }
  }

  .btn-md.btn-icon-left{
    .icon:first-child{
      left:20px;
    }
  }
  .btn-lg.btn-icon-left{
    .icon:first-child{
      left:20px;
      font-size: 24px;
      margin-top: -12px;
    }
  }


  .btn-md.btn-icon-right{
    .icon-chevron-right{
      right:20px;
    }
  }
  .btn-lg.btn-icon-right{
    .icon-chevron-right{
      right:20px;
      margin-top: -11px;
    }
  }

}

@media (min-width: $screen-sm-min) {
  .btn{
    padding-top: $padding-base-vertical;
    padding-bottom: $padding-base-vertical;
  }
  .btn-xs{
    padding-top: $padding-xs-vertical;
    padding-bottom: $padding-xs-vertical;
  }

  .btn-sm{
    padding-top: $padding-small-vertical;
    padding-bottom: $padding-small-vertical;
  }

  .btn-md{
    padding-top: $padding-md-vertical;
    padding-bottom: $padding-md-vertical;

  }

  .btn-lg{
    padding-top: $padding-large-vertical;
    padding-bottom: $padding-large-vertical;
  }
}*/


/* Button Full 100% Width
-----------------------------------------------------------------*/

/*
@-webkit-keyframes shake-arrow {
  from, 50%, 100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  25%, 75% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
}

@keyframes shake-arrow {
  from, 50%, 100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  25%, 75%  {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
}


.btn.btn-full {
  display: block;
  white-space: normal;
  margin: 0;
  height: auto;
  line-height: 1.6;
  padding: 30px 0;
  font-size: 20px;
  font-weight: normal;
  background-color: #eeeeee;
  font-family: $font-family-italic;
  text-transform: none;
  text-align:center;
  @include border-radius(0);
  color: $body-color;

  .icon{
    font-size: 18px;
    margin-left:5px;
  }

  em,
  .em,
  .icon,
  strong{
    color: #111;
  }

  &:hover,
  &:focus{
    .icon{
      -webkit-animation-name: shake-arrow;
      animation-name: shake-arrow;
      -webkit-animation-duration: 1s;
      animation-duration: 1s;
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
    }
  }

}

.btn.btn-full.btn-full-light {
  background-color: #fff;
}

.btn.btn-full-primary{
  em, .em, strong, .icon{
    color: $primary-color;
  }
}

.btn.btn-full-secondary{
  em, .em, strong, .icon{
    color: $secondary-color;
  }
}


.btn.btn-full-terciary{
  em, .em, strong, .icon{
    color: $terciary-color;
  }
}


.btn.btn-full-quaternary{
  em, .em, strong, .icon{
    color: $quaternary-color;
  }
}


@media (min-width: $screen-xs-min) {
  .btn.btn-full {
    font-size: 30px;
    padding: 40px 0;
    .icon{
        font-size: 22px;
    }
  }
}

@media (min-width: $screen-sm-min) {
  .btn.btn-full {
    padding: 50px 0;
  }
}


@media (min-width: $screen-md-min) {
  .btn.btn-full {
    padding: 70px 0;
  }
}


body:not(.device-touch) .btn{
  -webkit-transition: all .25s ease-in-out;
  -o-transition: all .25s ease-in-out;
  transition: all .25s ease-in-out;
}
*/

