/*-----------------------------------------------------------------------------------

Header

-----------------------------------------------------------------------------------*/


/* ----------------------------------------------------------------
	Top Bar
-----------------------------------------------------------------*/

#top-bar {
	position: relative;
	background-color:#E9F0FA;
	@include gradient-horizontal(#F5F8FD, #D4E2F3);
	height: $topbar-height;
	line-height: $topbar-line-height;
	font-size: 12px;
	font-family: $secondary-font;
	color: $topbar-color;
	text-align:right;
	.top-phone{
		.icon{
			font-size: 16px;
		    color: $brand-title-color;
		    top: 2px;
		    margin-right: 7px;
		    position: relative;
		}
		display:inline-block;
		padding:0 10px;
		padding-right:30px; 
        font-size: 1.2em;
		}
}

/* ----------------------------------------------------------------
	Header
-----------------------------------------------------------------*/


#header {
	position: relative;
	background-color: #FFF;
	border-bottom: 1px solid #F5F5F5;
	.container {
		position: relative;
	}
	&.full-header {
		border-bottom-color: #EEE;
	}
	#header-wrap{
		//overflow:hidden;
		background: #fff;
	}

}

body.stretched #header.full-header .container {
	width: 100%;
	padding: 0 30px;
}


/* ----------------------------------------------------------------
	Logo
-----------------------------------------------------------------*/


.logo {
	position: relative;
	float: left;
	font-family: $heading-font;
	font-size: 36px;
	line-height: 100%;
	z-index:101;
	a {
		display: block;
		color: #000;
		float:left;
		position:absolute;
		width: 150px;
		height: auto;
	    left: 0px;
	    top: 0px; 
	}

	img {
		display: block;
		max-width: 100%; 
		height: auto;
		margin: -2px 0px 0px 0px; 
	}

	a.standard-logo{
		display: block;
	}

	a.retina-logo {
		display: none;
	}
}


/* ----------------------------------------------------------------
	Primary Menu & Tiny Menu
-----------------------------------------------------------------*/

.header-menu {
	float: right;
	background-color: #fff;
	position:absolute;
	right:0;
	top:0;
}

.header-menu {
	ul {
		list-style: none;
		margin: 0;
	}

	> ul { float: left; }

	ul {
		li {
			position: relative;
			float: left;
			a {
				display: block;
				padding: 0 15px;
				color: $primary-menu-font-color;
				font-weight: $primary-menu-font-weight;
				font-size: $primary-menu-font-size;
				letter-spacing: $primary-menu-font-spacing;
				text-transform: $primary-menu-font-tt;
				font-family: $primary-menu-font;
				border-bottom:$header-menu-border solid transparent;
				-webkit-transition: all .4s ease;
				-o-transition: all .4s ease;
				transition: all .4s ease;
				text-align:center;
				&:hover,
				&:focus{
					background-color: #f5f5f5!important;
					border-bottom: $header-menu-border solid $primary-menu-font-color!important;
				}
			}

			&.active > a{
				color: $brand-color;
				border-bottom:$header-menu-border solid $brand-color!important;
			}

			ul.dropdown-menu{
				@include clearfix();
				padding:0;
				border:0;
			}

			ul li{
				width:100%;
				border-bottom: 1px solid #ddd;
				a{
					text-align:left;
					padding: 14px 11px 10px 25px;
					height: auto;
					border-bottom:0;
					background: #f5f5f5!important;
					border-left:$header-menu-border solid transparent;
					color: $primary-menu-font-color;
					&:hover,
					&:focus{
						background: #fff!important;
						border-bottom: 0!important;
						color: $primary-menu-font-color;
						border-left: $header-menu-border solid $primary-menu-font-color!important;
					}
				}
				&.active > a{
					background: $brand-color!important;
					color: #fff;
					border-left:$header-menu-border solid $brand-color!important;
					border-bottom: 0!important;
				}
			}

		}

	}
}


#tiny-menu{
	ul li a{
		font-size:32px;
		padding: 0 25px;
	}
	.side-panel-trigger{
		color: #a0a0a0;
		border-bottom:0;
		&:hover,
		&:focus{
			color: $primary-menu-font-color;
			border-bottom:none!important;
		}
	}
}



/* ----------------------------------------------------------------
	Sticky Header
-----------------------------------------------------------------*/

#header,
#header #header-wrap,
.header-menu,
.header-menu ul,
.header-menu ul > li,
.header-menu ul > li > a,
#side-header,
#side-header a { height: $sticky-header-height + 20; }
.header-menu ul > li > a,
#side-header a { line-height: $sticky-header-height + 20 + 2; }

.header-menu ul > li ul {
	height: auto;
	li{
		height: auto;
		a{
			height: auto;
			line-height: 1.5;
		}
	}
}

#header,
#header-wrap,
.logo > a,
.logo > a img,
.header-menu,
.header-menu  ul,
#side-header,
#side-header a, {
	-webkit-transition:  all .4s ease;
	-o-transition: all .4s ease;
	transition: all .4s ease;
}

.logo{
	height: $logo-height - 30;
	margin-bottom:-50px;
	-webkit-transition:  height .4s ease;
	-o-transition: height .4s ease;
	transition: height .4s ease;
}

.logo > a{
	height: $logo-height - 30;
}

.logo img{
	height: $logo-height - 30;
	-webkit-transition:  all .4s ease;
	-o-transition: all .4s ease;
	transition: all .4s ease;
}


#header-wrap { position: relative; }


@media (min-width: $screen-md-min) {

	#header,
	#header #header-wrap,
	.header-menu,
	.header-menu ul,
	.header-menu ul > li,
	.header-menu ul > li > a,
	#side-header,
	#side-header a { height: $header-height; }
	.header-menu ul > li > a,
	#side-header a { line-height: $header-height + 2; }


	.header-menu ul > li ul {
		height: auto;
		li{
			height: auto;
			a{
				height: auto;
				line-height: 1.5;
			}
		}
	}

	.header-menu{
		right:15px;
	}

	.logo > a{
		height: $logo-height;
		top: 15px;
	}

	.logo img{
		height: $logo-height;
	}
}


@media (min-width: $screen-lg-min) {
	#primary-menu {
		ul {
			li {
				a {
					padding-left:25px;
					padding-right:25px;
				}
				ul li a{
					padding-left: 21px;
					padding-right:35px;
				}
			}
		}
	}

	.logo{
		height: $logo-height;
		margin-bottom:-60px;
	}

	.logo > a{
		height: $logo-height;
	}

	.logo img{
		height: $logo-height;
	}
}


body.sticky-header #header #header-wrap {
	z-index: 199;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	background-color: #FFF;
	box-shadow: 0 0 10px rgba(0,0,0,0.1);
	-webkit-backface-visibility: hidden;
}

body.sticky-header #header,
body.sticky-header #header #header-wrap,
body.sticky-header #header .header-menu,
body.sticky-header #header .header-menu ul,
body.sticky-header #header .header-menu ul > li,
body.sticky-header #header .header-menu ul > li > a,
body.sticky-header #side-header,
body.sticky-header #side-header a { height: $sticky-header-height; }
body.sticky-header #header .header-menu ul > li > a,
body.sticky-header #side-header a { line-height: $sticky-header-height + 2; }
body.sticky-header #header #tiny-menu .phone-trigger{ border-bottom: none; }


body.sticky-header #header .header-menu ul > li ul {
	height: auto;
	li{
		height: auto;
		a{
			height: auto;
			line-height: 1.5;
		}
	}
}


body.sticky-header .logo{
	height: $logo-sticky-height;
	margin-bottom:-50px;
}

body.sticky-header .logo > a{
	top: 5px;
	height: $logo-sticky-height;
}
body.sticky-header .logo > a img{
	height: $logo-sticky-height;
}


/* ----------------------------------------------------------------
	Side Push Panel
-----------------------------------------------------------------*/

body.side-push-panel { overflow-x: hidden; }

#side-panel {
	position: fixed;
	top: 0;
	right: -300px;
	z-index: 399;
	width: 300px;
	height: 100%;
	background-color: #F5F5F5;
	overflow: hidden;
	-webkit-backface-visibility: hidden;
}

#side-panel #side-header{
	position: relative;
	background-color: #FFF;
	border-bottom:1px solid #dcdcdc;
	overflow:hidden;
	.side-panel-trigger {
		float:right;
		display: inline-block;
		font-size:26px;
		padding: 0 30px;
		width:100%;
		background: $primary-menu-bg;
		color: #a0a0a0;
		-webkit-transition: all .4s ease;
		-o-transition: all .4s ease;
		transition: all .4s ease;
		&:hover,
		&:focus{
			color: $primary-menu-font-color;
		}
	}
}

#side-panel .side-menu{
	ul{
		li{
			a{
				display: block;
				padding: 18px 35px 18px 30px;
				clear: both;
				line-height: 1.5;
				color: #707070;
				white-space: nowrap;
				overflow:hidden;
				text-overflow:ellipsis;
				font-weight: $primary-menu-font-weight;
				font-size: $primary-menu-font-size;
				letter-spacing: $primary-menu-font-spacing;
				text-transform: $primary-menu-font-tt;
				font-family: $primary-menu-font;
				border-left:$header-menu-border solid transparent;
				background-color: #fff;
				border-bottom:1px solid #dcdcdc;
				-webkit-transition: all .4s ease;
				-o-transition: all .4s ease;
				transition: all .4s ease;
				&:hover,
				&:focus{
					color: $body-color;
					border-left: $header-menu-border solid $primary-menu-font-color;
				}
			}

			&.active a{
				color: $brand-color;
				border-left: $header-menu-border solid $brand-color;
			}

			ul li a{
				padding-left: 45px;
			}

			&.active ul li{
				a{
					color: #707070;
					border-left: $header-menu-border solid transparent;
					&:hover,
					&:focus{
						color: $body-color;
						border-left: $header-menu-border solid $primary-menu-font-color;
					}
				}
				&.active a{
					color: $brand-color;
					border-left: $header-menu-border solid $brand-color;
				}
			}
			
			/*.collapse-menu {
			    width: 100%;
			    padding: 0;
			    margin: 0;
			    padding-left:35px;
			    font-size: 12px;
			    text-align: left;
			    background-color: #f2f2f2;
			    border: 0;
			    @include border-radius(0);
			    @include box-shadow(none);
			    ul{
			    	list-style:none;

			    }
			    > li{
			    	display:inline-block;
			    	padding: 10px 5px 5px 10px;
			    	a{
			    		color: red;
			    	}
			    }

			    .active > a{
			    	text-decoration: none;
					color: $dropdown-link-hover-color;
					background-color: $dropdown-bg;
					&:hover,
					&:focus{
						background-color: $dropdown-bg;
					}
			    }
	  
			}*/

		}

	}

}



body.side-panel-left #side-panel {
	left: -300px;
	right: auto;
}

body.side-push-panel.stretched #wrapper,
body.side-push-panel.stretched.sticky-header #header .container { right: 0; }

body.side-panel-left.side-push-panel.stretched #wrapper,
body.side-panel-left.side-push-panel.stretched.sticky-header #header .container {
	left: 0;
	right: auto;
}

#side-panel .side-panel-wrap {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	width: 330px;
	height: 100%;
	overflow: auto;
	//padding: 50px 70px 50px 40px;
}

body.device-touch #side-panel .side-panel-wrap { overflow-y: scroll; }

/*
#side-panel .widget {
	margin-top: 40px;
	padding-top: 40px;
	width: 220px;
	border-top: 1px solid #E5E5E5;
}

#side-panel .widget:first-child {
	padding-top: 0;
	border-top: 0;
	margin: 0;
}
*/

body.side-panel-open #side-panel { right: 0; }

body.side-panel-left.side-panel-open #side-panel {
	left: 0;
	right: auto;
}

body.side-push-panel.side-panel-open.stretched #wrapper,
body.side-push-panel.side-panel-open.stretched.sticky-header #header .container { right: 300px; }

body.side-panel-left.side-push-panel.side-panel-open.stretched #wrapper,
body.side-panel-left.side-push-panel.side-panel-open.stretched.sticky-header #header .container {
	left: 300px;
	right: auto;
}

/*#side-panel-trigger {
	display: block;
	cursor: pointer;
	z-index: 11;
	//margin-right: 5px;
}*/


//#side-panel-trigger-close a { display: none; }

#side-panel,
body.side-push-panel.stretched #wrapper,
body.side-push-panel.stretched #header .container {
	-webkit-transition: right .4s ease;
	-o-transition: right .4s ease;
	transition: right .4s ease;
}

body.side-panel-left #side-panel,
body.side-panel-left.side-push-panel.stretched #wrapper,
body.side-panel-left.side-push-panel.stretched #header .container {
	-webkit-transition: left .4s ease;
	-o-transition: left .4s ease;
	transition: left .4s ease;
}

.body-overlay {
	opacity: 0;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0,0,0,0.6);
	z-index: -1;
	pointer-events: none;
	-webkit-backface-visibility: hidden;
	-webkit-transition: opacity .3s ease;
	-o-transition: opacity .3s ease;
	transition: opacity .3s ease;
}

body.side-panel-open .body-overlay {
	opacity: 1;
	z-index: 298;
	pointer-events: auto;
}