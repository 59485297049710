/* ----------------------------------------------------------------
Comments
-----------------------------------------------------------------*/


#comments {
	position: relative;
}

.comment-list {
	list-style: none;
	padding: 0;
	margin: 0 0 60px;
	border-bottom: 2px solid #e5e5e5;

	li{
		position:relative;
		padding:0;
		padding-top:30px;
		margin:0;
		border-bottom: 2px solid #e5e5e5;

		&:first-child{
			padding-top:0;
		}
		&:last-child{
			border:0;
		}
	}


	ol.children{
		list-style: none;
		padding: 0;
		border-top: 2px solid #e5e5e5;
		li{
			margin-left: 0;
			&:first-child{
				padding-top:30px;
			}
		}
	}

	.comment-body{
		@include clearfix();
	}

	.comment-meta{
		position:relative;
		padding-left: 90px;
		min-height:70px;
	}

	.comment-author {
		.fn{
			font-family: $secondary-font;
			font-size: 20px;
			text-transform: uppercase;
			font-weight: normal;
			display:block;
			padding-top:20px;
		}
		.says{
			display:none;
		}
		img{
			border:1px solid #f5f5f5;
			position:absolute;
			top:0;
			left:0;
		}
	}

	.comment-metadata,
	.comment-metadata a{
		color: $grey-color;
		font-size: 16px;
	}

	.comment-content{
		padding-top:30px;
		padding-bottom: 10px;
		font-size:18px;
		line-height: 1.8;
	}

}

.comment-reply-link {
	display: inline-block;
	position: absolute;
	top: 30px;
	left: auto;
	right: 0;
	color: #CCC;
	font-size: 14px;
	line-height: 1;
	&:hover,
	&:focus {
		color: #888;
	}
}

.comment-awaiting-moderation{
	margin-left: -90px;
    margin-top: 20px;
    margin-bottom: -10px;
    color: $secondary-color;
}

@media (max-width: $screen-sm-max){
	h3.border-title,
	h3.comment-reply-title{
		font-size:24px;
	}
	.comment-list .comment-content{
		font-size:16px;
	}
}




@media (min-width: $screen-sm-min){
	.comment-list {
		li{
			ol.children{
				li{
					margin-left: 30px;
				}
			}
		}
	}
}

