/*-----------------------------------------------------------------------------------

Section Blocks

-----------------------------------------------------------------------------------*/


/* Testimonials */


.section-testimonials{
	background: #eeeeee;
}

.testimonial {
	blockquote{
	    margin: 0;
	    padding: 10px 0;
	    border: 0;
	    font-size: 20px;
	    text-align: left;
	    line-height:1.5;
	    &::before,
		&::after{
			display:none;
		}
	}
}

.testimonial-author{
	width:100%;
	float:right;
	position:relative;
	padding-right: 90px;
	min-height: 80px;
	padding-top:15px;
	margin-bottom: 40px;
	.author-name,
	.author-position{
		text-align:right;
		display: block;
		font-size:16px;
		overflow:hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
	.author-name{
		font-size: 20px;
		text-transform: uppercase;
		margin-bottom:6px;
	}
	.thumb{
		display:block;
		width:70px;
		height:70px;
		position:absolute;
		right:0;
		top:0;
		background-color: #fff;
		background-image: url('#{$img-path}avatar.png');
		background-size: cover;
    	background-repeat: no-repeat;
	}
}



@media (min-width: $screen-md-min) {
  .section-testimonials{
  	.btn-lg{
		padding-left: 40px;
		padding-right:60px;
	}
  }
  .testimonial-author{
    margin-bottom:0;
  }
}


/* Section Search */

.section-search{
	overflow: visible;
}