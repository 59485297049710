article.product {
  .breadcrumb {
	 background: white;
	 font-size: 0.8em;
	 color: $grey-color; 
	 margin: 0px; padding: 0px;

	 a {
       color: $grey-color;
	 }
	 a:hover {
       color: lighten ($grey-color, 20%);
	 }

  }

  h1 { margin: 0px 0px 0px 0px; padding: 0px; font-size: 2em; }
  h2 { margin: 0px 0px 0px 0px; padding: 0px; font-size: 1.5em; }
  h3 { margin: 0px 0px 10px 0px; padding: 0px; font-size: 1.5em; }

    
  .resume {

    ul {
      margin: 10px 0px 0px 0px;
      li { margin: 0px 0px 0px 0px; padding: 0px 0px 0px 20px; }
    }

  };

  .content {
    padding: 0px 10px 0px 0px;
  }
  
 }