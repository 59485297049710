/*-----------------------------------------------------------------------------------

Posts

-----------------------------------------------------------------------------------*/




/* Single post */


@media (min-width: $screen-md-min) {
	.row-single{
		margin-right: - $grid-gutter-width;
	}
	.col-single{
		padding:0;
	}
}


.hentry {
	position: relative;
}

.entry-img,
.entry-img > a,
.entry-img img {
	display: block;
	position: relative;
	width: 100%;
	height: auto;
}

.entry-header{

}


.entry-title {
	line-height:1.3;
	font-size: 26px;
}

.entry-time{
	display:block;
	font-family: $secondary-font-light;
	font-weight:normal;
	line-height:1.3;
	font-size: 16px;
	color: #999;
	margin-bottom:10px;
}


.entry-content {
	position: relative;
	font-size: $font-size-md;
	p{
		line-height:1.8;
	}
	blockquote{
		p{
			text-align:center;
		}
	}
}

.section-author{
	padding-bottom:20px;
}

.post-author{
	min-height:140px;
	.author-thumb {
	    display: block;
	    width: 70px;
	    height: 70px;
	    position: absolute;
	    left: $grid-gutter-width / 2;
	    top: 0;
	    background-color: #fff;
	    background-image: url("#{$img-path}avatar.png");
	    -webkit-background-size: cover;
	    background-size: cover;
	    background-repeat: no-repeat;
	    img{
	    	height:70px;
	    }
	}
	.author-details{
		margin-left: 100px;
		.author-name{
			display:block;
			font-family: $secondary-font-light;
			font-size: 20px;
			text-transform: uppercase;
			margin-bottom:5px;
			line-height:1.2;
		}
		.author-resume{
			font-size: 16px;
			margin-top:0;
			margin-bottom:0;
		}
	}
}


@media (min-width: $screen-sm-min){

	.post-author{
		min-height:200px;
		.author-thumb {
		    width: 134px;
		    height: 134px;
		    position: absolute;
		    left: $grid-gutter-width / 2;
		    top: 0;  
		    img{
		    	height: 134px;
		    }
		}
		.author-details{
			margin-left: 175px;
			.author-name{
				font-size: 42px;
			}
			.author-resume{
				margin-left:0;
				font-size: 18px;
			}
		}
	}
}

@media (min-width: $screen-xs-min) and (max-width: $screen-sm-max){
    .post-author{
		.author-thumb {
			left: $containter-mobile-padding;
		}
	}
}



/* ----------------------------------------------------------------
	Blog (ideas)
-----------------------------------------------------------------*/

.posts-wrapper{
	@include clearfix();
}

.posts{
	@include clearfix();
	.hentry{
		position:relative;
		width:100%;
		float:left;
		margin-bottom: 30px;

		> a{
			position:relative;
			display:block;
		}
		.entry-img{
			position:relative;
			overflow:hidden;
			background: #d5d5d5;
		}

		.entry-header{
			margin-top: 25px;
		}

		&:nth-child(odd){
			clear:left;
		}

	}

}



@media (min-width: $screen-xs-min) {
	.post-wrapper{
		margin-left:-16px;
		margin-right:-16px;
	}
	.posts{
		margin-left:-10px;
		margin-right:-10px;
		.hentry{
			width:50%;
			padding-left: 10px;
			padding-right: 10px;
		}
	}

	.hentry{
		.entry-header{
			margin-right: 30px;
		}
	}
}


@media (min-width: $screen-md-min) {

	.entry-title {
		font-size:28px;
	}

	.entry-time{
		font-size: 18px;
	}

	.posts{
		margin-left:-16px;
		margin-right:-16px;
		.hentry{
			width:33%;
			padding-left: 16px;
			padding-right: 16px;
			&:nth-child(odd){
				clear:none;
			}
			&:nth-child(3n+1){
				clear:left;
			}
		}
	}

	.main{
		.posts{
			margin-left:-16px;
			margin-right:-16px;
			.hentry{
				width:50%;
				padding-left: 16px;
				padding-right: 16px;
				&:nth-child(3n+1){
					clear:none;
				}
				&:nth-child(odd){
					clear:left;
				}
				
			}
		}
	}

	/*.hentry{
		.entry-img,
		.entry-img > a{
			height: 269px;
		}
	}*/

}

@media (min-width: $screen-lg-min) {
	/*.hentry{
		.entry-img,
		.entry-img > a{
			height: 328px;
		}
		.entry-header{
			margin-right: 60px;
		}
	}*/
}


/* Tiny Entry */


.tiny-entry{
	min-height:150px;
	.entry-title{
		font-family: $secondary-font;
		text-transform: none;
		font-weight:normal;
		font-size: 16px;
		margin-bottom:10px;
	}
	.entry-time{
		font-family: $secondary-font-light;
		font-size:14px;
    	margin-top: -5px;
    	margin-bottom: 5px;
	}
	.entry-content{
		font-size: 14px;
		margin-bottom:5px;
		.date{
			font-size:12px;
			color: $brand-color;
		}
	}
}


/* Post Elements
-----------------------------------------------------------------*/

img,
div {
	&.alignleft {
		float: left;
		margin: 5px 20px 13px 0;
		max-width: 100%;
	}
}

div {
	&.alignleft,
	&.alignnone,
	&.aligncenter,
	&.alignright {
		> img {
			display: block;
			float: none;
		}
	}
}

img.alignnone,
img.aligncenter,
div.alignnone,
div.aligncenter {
	display: block;
	margin: 10px 0;
	float: none;
}

img.aligncenter,
div.aligncenter,
div.aligncenter img {
	margin-left: auto;
	margin-right: auto;
	clear: both;
}

img,
div {
	&.alignright {
		float: right;
		margin: 5px 0 13px 20px;
	}
}

.wp-caption {
	text-align: left;
	margin: 40px 0;
    padding: 0;
    line-height: 1.5;
    background-color: #fff;
    border: 0;
    border-radius: 0;
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
    max-width:100%;
	img,
	img a {
		display: block;
		margin: 0;
	}
}

.wp-caption-text,
p.wp-caption-text {
	display: inline-block;
	margin: 10px 0 0 0;
	padding: 5px 10px;
	color: #a0a0a0;
	font-size:16px;
}

.wp-smiley {
	max-height: 13px;
	margin: 0 !important;
}

