/* ----------------------------------------------------------------

Bootstrap adjustments

-----------------------------------------------------------------*/


/* Containers */

@media (min-width: $screen-xs-min) and (max-width: $screen-sm-max){
  .featured-header > .container,
  .default-header > .container,
  .content-wrap > .container,
  .content-wrap > .block > .container,
  .content-wrap .section > .container,
  .footer-widgets > .container,
  .footer-company > .container{
    padding-left: $containter-mobile-padding;
    padding-right: $containter-mobile-padding;
  }
}


/* Lead block */


.lead {
    margin-bottom: 40px;
    font-size: 18px;
    font-weight: normal;
    line-height: 1.6;
}


/* Lead Text */

.widget .lead{
  color: $brand-color;
  font-size: 18px;
}


@media (min-width: $screen-sm-min){
  .lead {
      font-size: 22px;
  }
}



/*---------------------------------------------------
Bootstrap Sliding Dropdown Menu
----------------------------------------------------*/


.caret {
  position:relative;
    display: inline-block;
    width: 15px;
    height: 15px;
    margin-left: 2px;
    vertical-align: middle;
    border:0;
    line-height:1;
    font-size:12px;
    &:before{
      position:absolute;
      top:0;
      right:0;
      font-family: $ionicons-font-family;
      content: $ionicon-var-chevron-down;
      color: $grey-color;

    }
    
}


.dropdown-menu {
  display: block;
  -webkit-transform-origin: top;
  transform-origin: top;
  -webkit-animation-fill-mode: forwards;  
  animation-fill-mode: forwards; 
  -webkit-transform: scale(1, 0);
  transition: all 0.2s ease-out;
  -webkit-transition: all 0.2s ease-out;
  @include opacity(0);
  visibility: hidden;
}

.dropup .dropdown-menu {
  -webkit-transform-origin: bottom;
  transform-origin: bottom;  
}

.dropdown:hover .dropdown-menu,
.open > .dropdown-menu {
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);  
  @include opacity(1);
  visibility: visible;
}

