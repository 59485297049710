/*-----------------------------------------------------------------------------------

Footer

-----------------------------------------------------------------------------------*/


#footer {
	position: relative;
	background-color: #fff;
	.footer-widgets {
		position: relative;
		padding: $margin-base 0;
	}
}

.device-lg,
.device-md {
	&.sticky-footer {
		#top-bar { z-index: 299; }
		#header { z-index: 199; }
		#slider,
		#page-submenu,
		#page-title,
		#content,
		#slider,
		#page-submenu,
		#page-title,
		#content { z-index: 2; }
		#footer {
			position: fixed;
			top: auto;
			bottom: 0;
			left: 0;
			width: 100%;
			z-index: 1;
		}
	}
}


.footer-widgets{
	.widget{
		margin: 0 auto 15px;
		.widget-title{
			position:relative;
			text-transform:uppercase;
			font-family: $secondary-font;
			font-size: 20px;
			padding-left: 45px;
			.icon{
				position:absolute;
				left:0;
				top: -8px;
				font-size:32px;
				margin-left:5px;
			}
		}
	}

}


.footer-company{
	position:relative;
	background-color: $brand-color;
	color: #fff;
	padding-top:50px;
	padding-bottom:50px;
	a:hover { color: white !important; }

	.lead{
		color:#fff;
		font-size: 23px;
		margin: 0 auto;
		font-family: $secondary-font-light;
		margin-right:30px;
		letter-spacing: 0.5pt;
		a:hover { color: white !important; }
	}

	.col-logo{
		margin: 0 auto;
		.address{
			font-size: 12px;
		    white-space: pre-line;
		    float: left;
		    clear: both;
		    display: block;
		    color: $brand-title-color;
		    font-family: $secondary-font;
		}
	}

    .col-slogan {
		padding-top: 50px;
		color: white !important;
		a { color: white !important; }
		a:hover { color: white !important; }

		.lead {
		  color: white !important;
          a { color: white !important; }
		  a:hover { color: white !important; }		
		}

	}

	.col-action{
		padding-top: 50px;
		.btn{
			font-family: $secondary-font;
			color: $brand-color!important;
		}
	}

	.logo-foot {
		position: relative;
		width:150px;
		/* height:150px; */
		height: auto;
		max-width:100%;
		img {
			display: block;
			max-width: 100%;
		}

		a.standard-logo {
			display: inline-block;
		}

		a.retina-logo {
			display: none;
		}
	}

}




ul.social-links{
		text-align:center;
		margin: 20px 0 10px;
		li{
			margin:5px;
			a{
				color:$brand-title-color;
				position:relative;
				width:34px;
				height:34px;
				display:block;
				text-align:center;
				transition: color 0.3s;
				&:before {
					content: '';
					position: absolute;
					width: 100%;
					height: 100%;
					@include border-radius(50%);
					top: -5px;
					left: -5px;
					padding:5px;
					-webkit-box-sizing: content-box;
    				-moz-box-sizing: content-box;
   					box-sizing: content-box;
					-webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
					-moz-transition: -moz-transform 0.3s, opacity 0.3s;
					transition: transform 0.3s, opacity 0.3s;
					-webkit-transform: scale(.8);
					-moz-transform: scale(.8);
					-ms-transform: scale(.8);
					transform: scale(.8);
					opacity: 0;
					-webkit-backface-visibility: hidden;

				}
				.icon{
					color:$brand-title-color;
					position:relative;
					font-size: 26px;
					z-index:1;
					//text-shadow: 1px 1px 1px rgba(0,0,0,0.004);
					//-webkit-backface-visibility: hidden;
				}
				&.si-twitter{
					&:before{
						background-color: $twitter-color;
					}
				}
				&.si-facebook{
					&:before{
						background-color: $facebook-color;
					}
				}
				&.si-linkedin{
					&:before{
						background-color: $linkedin-color;
					}
				}
				&.si-youtube{
					&:before{
						background-color: $youtube-color;
					}
				}
				&.si-googleplus{
					&:before{
						background-color: $googleplus-color;
					}
				}
				&.si-pinterest{
					&:before{
						background-color: $pinterest-color;
					}
				}
				&.si-vimeo{
					&:before{
						background-color: $vimeo-color;
					}
				}
				&.si-instagram{
					&:before{
						background-color: $instagram-color;
					}
				}
				&:focus,
				&:hover{
					color: #fff;
					.icon{
						color: #fff;
						//-webkit-backface-visibility: hidden;
					}
					&:before{
						-webkit-transform: scale(1);
						-moz-transform: scale(1);
						-ms-transform: scale(1);
						transform: scale(1);
						opacity: 1;
					}
				}
			}
		}
}


.footer-menus{
	background: #eee;
	padding-top:50px;
	padding-bottom:50px;
	a{
		background:transparent!important;
		padding:0;
		font-size: 18px;
		font-family: $secondary-font-light;
		border-bottom:1px solid #ddd;
		&:hover,
		&:focus{
			background:transparent!important;
			color: $brand-title-color;
		}
	}
}

.footer-copyrights {
	padding: 30px 0;
	background-color: #ddd;
	font-size: 12px;;
	font-family: $secondary-font;
	.foot-links{
		display:inline-block;
		margin:0;
		li{
			margin-left: 5px;
			a:hover, a:focus{
				color: $brand-title-color;
			}
		}
	}
}


@media (min-width: $screen-xs-min) {

}

@media (min-width: $screen-sm-min){

	.footer-social{
		ul.social-links{
			li{
				a{
					width:50px;
					height:50px;
					.icon{
						font-size: 36px;
					}
				}
			}
		}
	}
}


@media (min-width: $screen-md-min) {

	.footer-widgets{
		.widget-news{
			.lead{
				margin-bottom: 50px;
			}
		}
		.widget{
			width:85%;
		}
		.col-first .widget{
			float:left;
		}

		.col-middle .widget{
			float:right;
		}

		.col-last .widget{
			float:right;
		}
	}

	.footer-copyrights {
		.foot-links{
			li{
				margin-left: 35px;
			}
		}
	}
}


#goToTop{
	position:fixed;
	bottom: 30px;
	right: 30px;
	width: 50px;
	height: 50px;
	text-align:center;
	font-size: 26px;
	background: $brand-color-light;
	@include transition(all .35s ease);

	@include border-radius(50%);
	cursor: pointer;
	.icon{
		color: #fff;
		position:relative;
		top:5px;
	}
	&:hover,
	&:focus{
		background: $brand-dark-color;
		.icon{
			color: #fff;
		}
	}
}