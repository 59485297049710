/*-----------------------------------------------------------------------------------

Sliders

-----------------------------------------------------------------------------------*/

.slider-container {
	margin:0 auto;
	position:relative;
	overflow:hidden;
	width:100%;
	-webkit-backface-visibility:hidden;
	/*-moz-backface-visibility:hidden;
	-ms-backface-visibility:hidden;
	-o-backface-visibility:hidden;
	backface-visibility:hidden;*/
	/* Fix of Webkit flickering */
	z-index:1;
}

.slider-parallax-outer{
	position:relative;
	width:100%;
}

.slider-parallax,
.slider-parallax .slider-parallax-outer {
	visibility: visible;
	-webkit-transform: translate(0,0);
	-ms-transform: translate(0,0);
	-o-transform: translate(0,0);
	transform: translate(0,0);
	-webkit-backface-visibility: hidden;
}


.slider,
.tiny-slider {
	position:relative;
	width:100%;
	/*-webkit-transition-property:-webkit-transform, left, top;
	-webkit-transition-duration:.2s;
	-webkit-transform:translate3d(0px,0,0);
	-webkit-transition-timing-function:ease;
	
	-moz-transition-property:-moz-transform, left, top;
	-moz-transition-duration:.2s;
	-moz-transform:translate3d(0px,0,0);
	-moz-transition-timing-function:ease;
	
	-o-transition-property:-o-transform, left, top;
	-o-transition-duration:.2s;
	-o-transform:translate3d(0px,0,0);
	-o-transition-timing-function:ease;
	-o-transform:translate(0px,0px);
	
	-ms-transition-property:-ms-transform, left, top;
	-ms-transition-duration:.2s;
	-ms-transform:translate3d(0px,0,0);
	-ms-transition-timing-function:ease;
	
	transition-property:transform, left, top;
	transition-duration:.2s;
	transform:translate3d(0px,0,0);
	transition-timing-function:ease;*/

	/*-webkit-box-sizing: content-box;
	-moz-box-sizing: content-box;
	box-sizing: content-box;*/
}

.bx-wrapper,
.slider-wrapper {
  position: relative;
  margin: 0 auto;
  padding: 0;
  *zoom: 1;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}

.bx-wrapper img,
.slider-wrapper img {
  max-width: 100%;
  display: block;
}

.slider,
.bxslider {
  margin: 0;
  padding: 0;
}

ul.slider,
ul.bxslider {
  list-style: none;
}

.bx-viewport {
  /*fix other elements on the page moving (on Chrome)*/
  -webkit-transform: translatez(0);
}

/* THEME */
.bx-wrapper,
.slider-wrapper {
  background: #fff;
}

.slider-wrapper .bx-pager,
.slider-wrapper .bx-controls-auto,
.bx-wrapper .bx-pager,
.bx-wrapper .bx-controls-auto {
  position: absolute;
  bottom: 10px;
  width: 100%;
  z-index:100;
}

/* LOADER */
.slider-wrapper .bx-loading,
.bx-wrapper .bx-loading {
  /*min-height: 50px;
  background: url('images/bx_loader.gif') center center no-repeat #ffffff;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2000;*/
}


/* PAGER */
.slider-wrapper .bx-pager,
.bx-wrapper .bx-pager {
  text-align: center;
}

.slider-wrapper .bx-pager .bx-pager-item,
.slider-wrapper .bx-controls-auto .bx-controls-auto-item,
.bx-wrapper .bx-pager .bx-pager-item,
.bx-wrapper .bx-controls-auto .bx-controls-auto-item{
	display:inline-block;
	*zoom: 1;
    *display: inline;
}
.slider-wrapper .bx-pager a,
.bx-wrapper .bx-pager a {
	background: transparent;
    text-indent: -9999px;
    display: block;
    width: 12px;
    height: 12px;
    border:2px solid #fff;
    margin: 0 5px;
    outline: 0;
    @include border-radius(50%);


  	/*font-size:21px;
	width:22px;
	height:22px;
	opacity:1;
	margin:0;
	color: #fff;
	position: relative;
	display:inline-block;
	cursor: pointer;
	font-family: $ionicons-font-family;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-transition: all .3s ease;
	-o-transition: all .3s ease;
	transition: all .3s ease;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
	&:before{
		content: $ionicon-var-android-radio-button-on;
	}*/
}

.slider-wrapper .bx-pager a:hover,
.slider-wrapper .bx-pager a.active,
.slider-wrapper .bx-pager a:focus,
.bx-wrapper .bx-pager a:hover,
.bx-wrapper .bx-pager a.active,
.bx-wrapper .bx-pager a:focus {
  background-color: $secondary-color;
  border-color: $secondary-color;
}

/*
.slider-wrapper .bx-pager a.active,
.bx-wrapper .bx-pager a.active{
	&:before { 
		content: $ionicon-var-record;
	}
}*/

/* DIRECTION CONTROLS (NEXT / PREV) */

.slider-wrapper .bx-controls-direction a,
.bx-wrapper .bx-controls-direction a {
  position: absolute;
  cursor: pointer;
  top: 50%;
  margin-top: -26px;
  outline: 0;
  width: 62px;
  height: 62px;
  color: #fff;
  color: rgba(255,255,255,0.3);
  background-color: transparent;
  border: 0;
  -webkit-transition: color .3s ease-in-out;
  -o-transition: color .3s ease-in-out;
  transition: color .3s ease-in-out;
  z-index: 100;
}

.slider-wrapper .bx-prev,
.bx-wrapper .bx-prev {
  left: -15px;
}

.slider-wrapper .bx-next,
.bx-wrapper .bx-next {
  right: -15px;
}

.slider-wrapper .bx-prev:hover,
.slider-wrapper .bx-prev:focus,
.slider-wrapper .bx-next:hover,
.slider-wrapper .bx-next:focus,
.bx-wrapper .bx-prev:hover,
.bx-wrapper .bx-prev:focus,
.bx-wrapper .bx-next:hover,
.bx-wrapper .bx-next:focus {
   color: #fff;
}


.slider-wrapper .bx-controls-direction a.disabled,
.bx-wrapper .bx-controls-direction a.disabled {
  color: #fff;
  display:none;
  cursor: default;
}

.slider-wrapper .bx-controls-direction a i,
.bx-wrapper .bx-controls-direction a i{
	line-height: 62px;
	height: 62px;
	width: 36px;
	// width: 100px;
	color:inherit;
	font-size: 34px;
	text-align: center;
	margin-left: 22px;
}

.slider-wrapper .bx-next i,
.bx-wrapper .bx-next i { margin-left: 10px; }



/* AUTO CONTROLS (START / STOP) */

/*.bx-wrapper .bx-controls-auto {
  text-align: center;
}
.bx-wrapper .bx-controls-auto .bx-start {
  display: block;
  text-indent: -9999px;
  width: 10px;
  height: 11px;
  outline: 0;
  background: url('images/controls.png') -86px -11px no-repeat;
  margin: 0 3px;
}
.bx-wrapper .bx-controls-auto .bx-start:hover,
.bx-wrapper .bx-controls-auto .bx-start.active,
.bx-wrapper .bx-controls-auto .bx-start:focus {
  background-position: -86px 0;
}
.bx-wrapper .bx-controls-auto .bx-stop {
  display: block;
  text-indent: -9999px;
  width: 9px;
  height: 11px;
  outline: 0;
  background: url('images/controls.png') -86px -44px no-repeat;
  margin: 0 3px;
}
.bx-wrapper .bx-controls-auto .bx-stop:hover,
.bx-wrapper .bx-controls-auto .bx-stop.active,
.bx-wrapper .bx-controls-auto .bx-stop:focus {
  background-position: -86px -33px;
}
*/


/* SLIDER CAPTION */

.slider-caption {
	color: #fff;
	font-size: 16px;
	font-weight: normal;
	-webkit-transition: top .3s ease;
	-o-transition: top .3s ease;
	transition: top .3s ease;
	text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);

	.slide-title {
		font-size: 22px;
		letter-spacing: 1px;
		text-transform: uppercase;
		font-weight: normal;
		font-family: $secondary-font-light;
		line-height: 1.2;
		margin-bottom: 20px;
		color: #fff;
	}

	p {
		margin-bottom: 20px;
		&:last-child{
			margin-bottom:0;
		}
	}

	.slider-caption-inside{
		p:last-child{
			margin-bottom:20px;
		}
	}

	.big-btn{
		text-shadow:none;
		padding-top: 15px;
		padding-bottom:15px;
	}

	&.slider-caption-right {
		text-align:right;
	}

	&.slider-caption-center {
		text-align: center;
		margin: 0 auto;
	}
}


/* SLIDE (VERTICAL CENTER) */

.slider{

  .slide{
  	position:absolute;
  	background-position: 50% 50%;
  	backgrond-repeat: no-repeat;
  	background-size: cover;
  	overflow: hidden;
  	margin: 0;
  	.container {
		position: relative;
		height: 100%;
		padding-left: 35px;
		padding-right: 35px;
		.slider-caption-wrapper{
			position:relative;
			width:100%;
			height:100%;
		}
		.slider-caption{
			position:absolute;
			width:96%;
			left:1%;
			bottom:90px;

			&.vcentered{
				position:relative;
				bottom: auto;
			}

			&.slider-vertical-top{
				bottom:auto;
				top: 90px;
			}

			.slider-caption-inside{
				@include clearfix();
				padding:20px 20px 0 20px;
				background: rgba(0,0,0,0.5);
			}

			.slider-caption-light{
				background: rgba(255,255,255,0.5);
				color: #333;
				text-shadow: none;
				.slide-title {
					color: #333;

				}
			}

			.slider-caption-light,
			.slider-caption-dark{
				p{
					margin-top:-10px;
				}
			}
		}
	}

	[data-caption-animate] {
		-webkit-backface-visibility: hidden;
	}

  }

}



/* FOOTER TINY SLIDERS */

.tiny-slider-wrapper {
  position: relative;
  margin: 0 auto;
  padding: 0;
  *zoom: 1;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}

.tiny-slider-wrapper .bx-controls{
	position:relative;
	//margin-top:20px;
}

.tiny-slider-wrapper .bx-controls-direction a {
  position: relative;
  width: 30px;
  height: 30px;
  font-size: 14px;
  &:hover,
  &:focus{
  	 color: $brand-color;
  }
}

.tiny-slider-wrapper .bx-controls-direction a.disabled {
  opacity: .3;
  cursor: default;
  &:hover,
  &:focus{
  	 opacity: .3;
  	 color: $brand-color;
  }
}

.tiny-slider-wrapper .bx-next{
	margin-left:10px;
}


/* RESPONSIVE SLIDER SIZES */

.slider-container,
.slider-container .slider-parallax-outer,
.slider-container .slide{
	height:400px;
	height: auto;
}

.slider-big,
.slider-big .slider-parallax-outer,
.slider-big .slide{
	height:400px;
}

@media (min-width: $screen-xs-min) {


	.slider-wrapper .bx-prev,
	.bx-wrapper .bx-prev {
	  left: -5px;
	}

	.slider-wrapper .bx-next,
	.bx-wrapper .bx-next {
	  right: -5px;
	}

	
	.slider-caption {
		font-size:16px;
		.slide-title {
			font-size: 36px;
			margin-bottom: 20px;
		}

		/*p {
			margin-bottom: 20px;
			p:last-child{
				margin-bottom:0;
			}
		}
		.slider-caption-inside{
			p:last-child{
				margin-bottom:20px;
			}
		}*/

	}

}


@media (min-width: $screen-sm-min) {

	.slider-big,
	.slider-big .slider-parallax-outer,
	.slider-big .slide{
		height:500px;
	}

	.slider{
		.slide{
		  	.container {
				padding-left: 60px;
				padding-right: 60px;
				.slider-caption{
					width: 80%;
					&.vcentered{
						width:80%;
						position:relative;
						left: 0;
					}
					

					&.slider-caption-right {
						text-align:right;
						right:0;
						&.vcentered{
							left:20%;
						}
						/*padding-right:0;
						padding-left:70px;*/
					}

					&.slider-caption-center {
						text-align:center;
						left: 10%;

					}
				}
			}
		}
	}


	.slider-caption {
		font-size:18px;
		.slide-title {
			font-size: 46px;
			margin-bottom: 30px;
		}

		p {
			margin-bottom: 30px;
			p:last-child{
				margin-bottom:0;
			}
		}
		.slider-caption-inside{
			p:last-child{
				margin-bottom:30px;
			}
		}

		.big-btn{
			padding-left: 70px;
			padding-right: 70px;
			font-size:22px;
		}
	}

}

@media (min-width: $screen-md-min) {

	.slider-big{
		.slider-wrapper .bx-pager,
		.slider-wrapper .bx-controls-auto,
		.bx-wrapper .bx-pager,
		.bx-wrapper .bx-controls-auto{
			bottom:20px;
		}
	}


	.slider{
		.slide{
		  	.container {
				padding-left: 15px;
				padding-right: 15px;
				.slider-caption{
					.slider-caption-inside{
						padding:50px 50px 0 50px;
					}
						.big-btn{
							padding-top: 20px;
							padding-bottom:20px;
						}
				}
			}
		}
	}


}

@media (min-width: $screen-lg-min) {

	.slider-big{
		.slider-wrapper .bx-pager,
		.slider-wrapper .bx-controls-auto,
		.bx-wrapper .bx-pager,
		.bx-wrapper .bx-controls-auto{
			bottom:30px;
		}
	}

	.slider-wrapper .bx-prev,
	.bx-wrapper .bx-prev {
	  left: 0;
	}

	.slider-wrapper .bx-next,
	.bx-wrapper .bx-next {
	  right: 0;
	}

	.slider-big,
	.slider-big .slider-parallax-outer,
	.slider-big .slide{
		height:600px;
	}

	.slider-caption {
		font-size:20px;
		.slide-title {
			font-size: 56px;
			margin-bottom: 50px;
			margin-left: -3px;
		}

		p {
			margin-bottom: 50px;
			p:last-child{
				margin-bottom:0;
			}
		}
		.slider-caption-inside{
			p:last-child{
				margin-bottom:50px;
			}
		}

		.big-btn{
			padding-left: 80px;
			padding-right: 80px;
			font-size:24px;
		}
	}

}


/* Slim Scroll */

.slimScrollBar{
    opacity: 0.5!important;
    border-radius: 0!important;
}


/* Clients Ticker Carousel */

.block-clients-carousel{
	/*width: 100%;*/
	margin: 0 auto 30px;
	.clients-ticker{
		.slide{
			@include opacity(0.6);
			@include transition(all .35s);
			&:hover, &:focus{
				@include opacity(1);
			}
		}
	}

	.bx-wrapper{
		background: $section-bg;
	}
}

.product, .arrow-lg {
 
 .slider-wrapper .bx-prev,
 .bx-wrapper .bx-prev {
   left: -20px; 
   color: #e2ebfb; 
   text-shadow: 2px 2px darken(#e2ebfb,20%);

 } 
 .slider-wrapper .bx-next, 
 .bx-wrapper .bx-next {
   color: #e2ebfb; 
   text-shadow: 2px 2px darken(#e2ebfb,20%);
 }

.slider-wrapper .bx-prev:hover,
.slider-wrapper .bx-prev:focus,
.slider-wrapper .bx-next:hover,
.slider-wrapper .bx-next:focus,
.bx-wrapper .bx-prev:hover,
.bx-wrapper .bx-prev:focus,
.bx-wrapper .bx-next:hover,
.bx-wrapper .bx-next:focus {
   color: #e2ebfb;
}

 .slider-wrapper .bx-controls-direction a i,
 .bx-wrapper .bx-controls-direction a i{
    font-size: 50px; 
  }

}




