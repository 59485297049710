/*-----------------------------------------------------------------------------------

Featured image title header

-----------------------------------------------------------------------------------*/

.featured-header-container {
	margin:0 auto;
	position:relative;
	overflow:hidden;
	width:100%;
	-webkit-backface-visibility:hidden;
	/*-moz-backface-visibility:hidden;
	-ms-backface-visibility:hidden;
	-o-backface-visibility:hidden;
	backface-visibility:hidden;*/
	/* Fix of Webkit flickering */
	z-index:1;
}

.featured-header {
	position:relative;
	width:100%;
	margin:0;
	padding:0;
	/*-webkit-transition-property:-webkit-transform, left, top;
	-webkit-transition-duration:.2s;
	-webkit-transform:translate3d(0px,0,0);
	-webkit-transition-timing-function:ease;
	
	-moz-transition-property:-moz-transform, left, top;
	-moz-transition-duration:.2s;
	-moz-transform:translate3d(0px,0,0);
	-moz-transition-timing-function:ease;
	
	-o-transition-property:-o-transform, left, top;
	-o-transition-duration:.2s;
	-o-transform:translate3d(0px,0,0);
	-o-transition-timing-function:ease;
	-o-transform:translate(0px,0px);
	
	-ms-transition-property:-ms-transform, left, top;
	-ms-transition-duration:.2s;
	-ms-transform:translate3d(0px,0,0);
	-ms-transition-timing-function:ease;
	
	transition-property:transform, left, top;
	transition-duration:.2s;
	transform:translate3d(0px,0,0);
	transition-timing-function:ease;*/

	/*-webkit-box-sizing: content-box;
	-moz-box-sizing: content-box;
	box-sizing: content-box;*/
}



/* Featured Header (VERTICAL CENTER) */

.featured-header{
  	background-position: 50% 50%;
  	background-repeat: no-repeat;
  	background-size: cover;
  	overflow: hidden;
  	margin: 0;
  	padding: 40px 0 30px;
  	.container {
		position: relative;
		height: 100%;
		.slider-caption-wrapper{
			position:relative;
			width:100%;
			height:100%;
		}
		.slider-caption{
			position:relative;
			width:96%;
			left: 2%;
			right:2%;

			.slide-title{
				margin-bottom:15px;
				margin-top:5px;
			}

			&.vcentered{
				position:relative;
				bottom: auto;
			}


			.slider-caption-inside{
				@include clearfix();
				padding:20px 40px 0 40px;
				background: rgba(0,0,0,0.5);
				display:inline-block;
			}

			&.slider-caption-right{
				left: 3%;
				right:0;
				.slider-caption-inside{
					padding-left:30px;
					padding-right: 30px;
				}
			}

			&.slider-caption-left{
				left: 0;
				right:3%;
				.slider-caption-inside{
					padding-left:30px;
					padding-right:30px;
				}
			}


			&.slider-vertical-top{
				bottom:auto;
				top: 90px;
			}


			.slider-caption-light{
				background: rgba(255,255,255,0.5);
				color: #333;
				text-shadow: none;
				.slide-title {
					color: #333;

				}
			}

			.slider-caption-light,
			.slider-caption-dark{
				p{
					margin-top:-10px;
				}
			}
		}
	}

	[data-caption-animate] {
		-webkit-backface-visibility: hidden;
	}

}

/* RESPONSIVE SLIDER SIZES */

.featured-header-container,
.featured-header-container .featured-header-outer,
.featured-header-container .featured-header{
	height: auto;
}




@media (min-width: $screen-xs-min) {

	.featured-header-container .featured-header{
		padding:50px 0 40px;
	}

	/*.featured-header-container .featured-header .slider-caption{
		position:relative;
		width:98%;
		left: 0.5%;
		font-size:16px;
		.slide-title {
			font-size: 36px;
			margin-bottom: 20px;
		}

		p {
			margin-bottom: 20px;
		}
	}*/

}


@media (min-width: $screen-sm-min) {

	.featured-header-container,
	.featured-header-container .featured-header-outer,
	.featured-header-container .featured-header{
		height:400px;
	}

	.featured-header-container .featured-header{
		padding:0;
	}

	.featured-header-container .featured-header{
		/*position:relative;
		left: 0;*/
		.container {
				padding-left: 15px;
				padding-right: 15px;
				.slider-caption{
					width: 98%;
					left:1%;
					right:1%;
					&.vcentered{
						/*width:80%;
						position:relative;
						left: 0;*/
					}

					&.slider-caption-right{
						left: 1%;
						right:0;
					}

					&.slider-caption-left{
						left: 0;
						right:1%;
					}

					&.slider-caption-right {
						text-align:right;
						
					}

					&.slider-caption-center {
						text-align:center;
					}
				}
		}
	}


}

@media (min-width: $screen-md-min) {

	.featured-header-container,
	.featured-header-container .featured-header-outer,
	.featured-header-container .featured-header{
		height:400px;
	}

	.featured-header-big,
	.featured-header-big .featured-header-outer,
	.featured-header-big .featured-header{
		height:500px;
	}

}

@media (min-width: $screen-lg-min) {

	.featured-header-container,
	.featured-header-container .featured-header-outer,
	.featured-header-container .featured-header{
		height:500px;
	}

	.featured-header-big,
	.featured-header-big .featured-header-outer,
	.featured-header-big .featured-header{
		height:600px;
	}


	.featured-header-container .featured-header .slider-caption{
		width:85%;
	}

}




/*-----------------------------------------------------------------------------------

Blog header

-----------------------------------------------------------------------------------*/

.header-blog{
	width:100%;

	.slider-caption {

		.slider-caption-inside{
			display:block!important;
		}

		.slide-title {
			font-size: 22px;
			letter-spacing: 1px;
			text-transform: uppercase;
			font-weight: normal;
			font-family: $secondary-font-light;
			line-height: 1.4;
			margin-bottom: 20px;
			color: #fff;
		}

		p {
			margin-bottom: 20px;
		}

		.author{
			font-family: $secondary-font-light;
			text-transform:uppercase;
			font-size: 25px;
			margin-bottom:5px;
		}

		.entry-time{
			color: #fff;
			font-size:16px;
			margin-bottom:10px;
			margin-top:30px;
		}

		.cat-links,
		.tags-links{
			display:block;
			font-size:16px;
			color:#999;
			//font-family: $secondary-font-light;
			//text-transform:uppercase;
			margin-bottom:25px;
			.icon{
				font-size:16px;
				margin-right:10px;
			}
			a{
				color: $secondary-color;
				&:hover, &:focus{
					color: darken($secondary-color, 10%);
				}
			}
		}

	}
}

.default-header{

	  margin: 0;
	  padding: 50px 0 40px;
	  background-color: $brand-title-bg;
	  border-bottom: 1px solid lighten($brand-title-color, 30%);

      background-position: 50% 50%;
	  background-repeat: no-repeat;
	  background-size: cover;
	  background-image: url('#{$img-path}header_bg.jpg');

    .default-header-inner {
    	text-align:right;
    	padding-right: 20px;
    	padding-left:100px;

		.lnk-back{
			margin-bottom:20px;
			display:inline-block;
			.icon{
				font-size:16px;
			}
		}

		.page-title,
		.header-title {
			letter-spacing: 1px;
			text-transform: uppercase;
			font-weight: normal;
			font-family: $secondary-font-light;
			line-height: 1.4;
			color: $brand-title-color;
		}

		.author{
			font-family: $secondary-font-light;
			text-transform:uppercase;
			font-size: 25px;
			margin-bottom:5px;
		}

		.entry-time{
			font-size:16px;
			margin-bottom:5px;
			color: #fff;
			text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.2);
		}

		.cat-links,
		.tags-links{
			font-size:16px;
			color:$brand-title-color;
			//font-family: $secondary-font-light;
			//text-transform:uppercase;
			.icon{
				font-size:16px;
				margin-right:10px;
			}
			/*a{
				color: $body-color;
				&:hover,
				&:focus{
					color: $brand-color;
				}
			}*/
		}

		&.slider-caption-right {
			text-align:right;
		}

		&.slider-caption-center {
			text-align: center;
			margin: 0 auto;
		}

	}
}


.header-stick-meta{
	padding: 20px 0;

	.author{
		font-family: $secondary-font-light;
		text-transform:uppercase;
		font-size: 16px;
		margin-bottom:5px;
	}

	.separator{
		color: #a0a0a0;
		margin: 0 5px;
		position:relative;
		top:-2px;
	}

	.entry-time{
		display:inline;
		color: #a0a0a0;
		font-size:16px;
		text-transform: none;
	}

	.cat-links,
	.tags-links{
		font-size:16px;
		.icon{
			font-size:16px;
			margin-right:10px;
			color: #a0a0a0;
		}
	}

}


@media (min-width: $screen-xs-min) {

	.header-blog{

		.slider-caption {
			font-size:16px;
			.slide-title {
				font-size: 30px;
			}
		}

	}

	.default-header{
    	.default-header-inner {
    		font-size:16px;
			.slide-title {
				font-size: 30px;
			}
    	}
    }

	.header-stick-meta{

		.author{
			font-size: 18px;
		}

	}

}


@media (min-width: $screen-sm-min) {
	.header-blog{
		.slider-caption {
			padding-left:0;
			padding-right:10%;

    		&.slider-caption-right{
				padding-left:10%;
				padding-right:0;
			}
			&.slider-caption-center{
				padding-left:5%;
				padding-right:5%;
			}

			.lnk-back{
				margin-bottom:20px;
			}
			font-size:18px;
			.slide-title {
				font-size: 36px;
			}
		}
	}

	.header-stick-meta{

		.author{
			font-size: 20px;
		}

	}

	.default-header{
    	.default-header-inner {
    		/*padding-left:0;
			padding-right:10%;

    		&.slider-caption-right{
				padding-left:10%;
				padding-right:0;
			}
			&.slider-caption-center{
				padding-left:5%;
				padding-right:5%;
			}*/
			.lnk-back{
				margin-bottom:20px;
			}
			font-size:18px;
			.slide-title {
				font-size: 36px;
			}
    	}
    }

}



@media (min-width: $screen-md-min) {
	.header-blog{
		.slider-caption {

			padding-left:0;
			padding-right:20%;

    		&.slider-caption-right{
				padding-left:20%;
				padding-right:0;
			}
			&.slider-caption-center{
				padding-left:10%;
				padding-right:10%;
			}


			.link-back{
				margin-bottom:50px;
			}
			.slide-title {
				font-size: 40px;
			}

			p {
				margin-bottom: 50px;
			}

			.author{
				margin-bottom:5px;
			}

			/*.entry-time{
				margin-bottom:50px;
			}*/

		}

		&.slider-container{
			.slider-caption{
				.slide-title{
					margin-bottom: 50px;
				}
			}
		}
	}

	.default-header{
    	.default-header-inner {

    		/*padding-left:0;
			padding-right:36%;

    		&.slider-caption-right{
				padding-left:36%;
				padding-right:0;
			}
			&.slider-caption-center{
				padding-left:18%;
				padding-right:18%;
			}*/

			.link-back{
				margin-bottom: 30px;
			}
			.header-title {
				font-size: 40px;
				margin-bottom: 20px;
				margin-left: -1px;
			}

			p {
				margin-bottom: 20px;
			}

			.author{
				margin-bottom:5px;
			}

			.entry-time{
				//margin-bottom:50px;
			}
    	}
    }

}

@media (min-width: $screen-lg-min) {

	.header-blog{
		.featured-header .slider-caption{
			width: 98%;
		}
		.slider-caption {
			
			padding-right:30%;
			padding-left:0;
			&.slider-caption-right{
				padding-left:30%;
				padding-right:0;
			}
			&.slider-caption-center{
				padding-left:10%;
				padding-right:10%;
			}

			font-size:20px;
			.slide-title {
				font-size: 42px;
			}
			
		}
	}

	.default-header{
    	.default-header-inner {
    		/*padding-right:46%;
			padding-left:0;
			&.slider-caption-right{
				padding-left:46%;
				padding-right:0;
			}
			&.slider-caption-center{
				padding-left:22%;
				padding-right:22%;
			}*/
			font-size: 20px;
			.header-title {
				font-size: 42px;
			}
	
    	}
    }

}



/*-----------------------------------------------------------------------------------*/
// Simple Page header
// -------------------------


.page-header {
  margin: 0;
  padding: 50px 0 40px;
  background-color: $brand-title-bg;
  border-bottom: 1px solid lighten($brand-title-color, 30%);
   background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('#{$img-path}header_bg.jpg');
  .page-title,
  .header-title{
  	color: $brand-title-color;
  	margin-bottom:0;
  	text-align:right;
  	margin-right: 20px;
  	margin-left: 150px;
  }
}



/*-----------------------------------------------------------------------------------

Author

-----------------------------------------------------------------------------------*/

.header-author {
  background-color: $brand-title-bg;
  border-bottom: 1px solid lighten($brand-title-color, 30%);
   background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('#{$img-path}header_bg.jpg');
  .post-author{
    .author-name{
       color: $brand-title-color;
    }
    .author-thumb{
      left: auto;
      right: 20px;
    }
    .author-details{
        margin-right: 100px;
        text-align:right;
        color: #999;
    }
  }
}


@media (min-width: $screen-sm-min){

  .header-author .post-author{
    min-height:170px;
    .author-thumb {
        left: auto; 
        right: 20px;
    }
    .author-details{
      margin-left:100px;
      margin-right: 175px;
    }
  }

}

@media (min-width: $screen-xs-min) and (max-width: $screen-sm-max){
  .header-author .post-author{
    .author-thumb {
      left: auto;
      right: $containter-mobile-padding;
    }
  }
}
