/*-----------------------------------------------------------------------------------

Layouts

-----------------------------------------------------------------------------------*/

/* ----------------------------------------------------------------
Grid two cols
-----------------------------------------------------------------*/
.main {
  @include make-md-column($main-sm-columns);
  .sidebar-primary & {
    @include make-md-column($main-sm-columns - $sidebar-sm-columns);
  }
}
.sidebar {
  @include make-md-column($sidebar-sm-columns);
}


/* ----------------------------------------------------------------
Sections
-----------------------------------------------------------------*/

.section {
	position: relative;
	margin: $section-margin 0;
	padding: $section-padding 0;
	background-color: $section-bg;
	overflow: hidden;

	.container {
		z-index: 2;
	}

	.container + .video-wrap {
		z-index: 1;
	}
}

.block{
    position: relative;
    margin: 0;
    padding: $section-padding 0;
    background-color: #fff;
    overflow: hidden;   
}

.parallax {
	background-color: transparent;
	background-attachment: fixed;
	background-position: 50% 50%;
	background-repeat: no-repeat;
    background-size:cover;
	overflow: hidden;
}

.mobile-parallax,
.video-placeholder {
	background-size: cover !important;
	background-attachment: scroll !important;
	background-position: center center;
}

.revealer-image {
	position: relative;
	bottom: -($section-padding * 2);
	transition: bottom .3s ease-in-out;
	-webkit-transition: bottom .3s ease-in-out;
	-o-transition: bottom .3s ease-in-out;
}

.section:hover .revealer-image { bottom: -$section-padding; }


/* ----------------------------------------------------------------
	Custom Columns & Grids
-----------------------------------------------------------------*/


/*
.postcontent {
	width: $postcontent;

	&.bothsidebar {
		width: $bothsidebar;
	}
}

.sidebar { width: $sidebar; }
.col_full { width: 100%; }
.col_half { width: 48%; }
.col_one_third { width: 30.63%; }
.col_two_third { width: 65.33%; }
.col_one_fourth { width: 22%; }
.col_three_fourth { width: 74%; }
.col_one_fifth { width: 16.8%; }
.col_two_fifth { width: 37.6%; }
.col_three_fifth { width: 58.4%; }
.col_four_fifth { width: 79.2%; }
.col_one_sixth { width: 13.33%; }
.col_five_sixth { width: 82.67%; }
*/

body.stretched .container-fullwidth {
	position: relative;
	padding: 0 60px;
	width: 100%;
}

/*

.postcontent,
.sidebar,
.col_full,
.col_half,
.col_one_third,
.col_two_third,
.col_three_fourth,
.col_one_fourth,
.col_one_fifth,
.col_two_fifth,
.col_three_fifth,
.col_four_fifth,
.col_one_sixth,
.col_five_sixth {
	display: block;
	position: relative;
	margin-right: 4%;
	margin-bottom: $margin-base;
	float: left;
}

.postcontent,
.sidebar { margin-right: $postcontent-margin; }

.col_full {
	clear: both;
	float: none;
	margin-right: 0;
}

.postcontent.col_last,
.sidebar.col_last { float: right; }

.col_last {
	margin-right: 0 !important;
	clear: right;
}

*/


/* Custom xxs grid size */

.col-xxs-1, .col-xxs-2, .col-xxs-3, .col-xxs-4,
.col-xxs-5, .col-xxs-6, .col-xxs-7, .col-xxs-8,
.col-xxs-9, .col-xxs-10, .col-xxs-11, .col-xxs-12 {
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
    position: relative;
}

@media (max-width: 480px) {
    .col-xxs-1,
    .col-xxs-2,
    .col-xxs-3,
    .col-xxs-4,
    .col-xxs-5,
    .col-xxs-6,
    .col-xxs-7,
    .col-xxs-8,
    .col-xxs-9,
    .col-xxs-10,
    .col-xxs-11 {
        float: left;
    }

    .col-xxs-1 {
        width: 8.333333333333332%;
    }

    .col-xxs-2 {
        width: 16.666666666666664%;
    }

    .col-xxs-3 {
        width: 25%;
    }

    .col-xxs-4 {
        width: 33.33333333333333%;
    }

    .col-xxs-5 {
        width: 41.66666666666667%;
    }

    .col-xxs-6 {
        width: 50%;
    }

    .col-xxs-7 {
        width: 58.333333333333336%;
    }

    .col-xxs-8 {
        width: 66.66666666666666%;
    }

    .col-xxs-9 {
        width: 75%;
    }

    .col-xxs-10 {
        width: 83.33333333333334%;
    }

    .col-xxs-11 {
        width: 91.66666666666666%;
    }

    .col-xxs-12 {
        width: 100%;
    }

    .col-xxs-push-1 {
        left: 8.333333333333332%;
    }

    .col-xxs-push-2 {
        left: 16.666666666666664%;
    }

    .col-xxs-push-3 {
        left: 25%;
    }

    .col-xss-push-4 {
        left: 33.33333333333333%;
    }

    .col-xxs-push-5 {
        left: 41.66666666666667%;
    }

    .col-xxs-push-6 {
        left: 50%;
    }

    .col-xxs-push-7 {
        left: 58.333333333333336%;
    }

    .col-xxs-push-8 {
        left: 66.66666666666666%;
    }

    .col-xxs-push-9 {
        left: 75%;
    }

    .col-xxs-push-10 {
       left: 83.33333333333334%;
    }

    .col-xxs-push-11 {
       left: 91.66666666666666%;
    }

    .col-xxs-pull-1 {
        right: 8.333333333333332%;
    }

    .col-xxs-pull-2 {
        right: 16.666666666666664%;
    }

    .col-xxs-pull-3 {
        right: 25%;
    }

    .col-xxs-pull-4 {
        right: 33.33333333333333%;
    }

    .col-xxs-pull-5 {
        right: 41.66666666666667%;
    }

    .col-xxs-pull-6 {
        right: 50%;
    }

    .col-xxs-pull-7 {
        right: 58.333333333333336%;
    }

    .col-xxs-pull-8 {
        right: 66.66666666666666%;
    }

    .col-xxs-pull-9 {
        right: 75%;
    }

    .col-xxs-pull-10 {
        right: 83.33333333333334%;
    }

    .col-xxs-pull-11 {
        right: 91.66666666666666%;
    }

    .col-xxs-offset-1 {
        margin-left: 8.333333333333332%;
    }

    .col-xxs-offset-2 {
        margin-left: 16.666666666666664%;
    }

    .col-xxs-offset-3 {
        margin-left: 25%;
    }

    .col-xxs-offset-4 {
        margin-left: 33.33333333333333%;
    }

    .col-xxs-offset-5 {
        margin-left: 41.66666666666667%;
    }

    .col-xxs-offset-6 {
        margin-left: 50%;
    }

    .col-xxs-offset-7 {
        margin-left: 58.333333333333336%;
    }

    .col-xxs-offset-8 {
        margin-left: 66.66666666666666%;
    }

    .col-xxs-offset-9 {
        margin-left: 75%;
    }

    .col-xxs-offset-10 {
        margin-left: 83.33333333333334%;
    }

    .col-xxs-offset-11 {
        margin-left: 91.66666666666666%;
    }

}  