/*-----------------------------------------------------------------------------------

Feature box

-----------------------------------------------------------------------------------*/

.block-features{ 
	.feature-btn{
		margin-top: 30px;
		margin-bottom: 30px;
	}
}
.feature-box {
    position: relative;
    margin: 0;
    padding: 0;
    text-align:center;
    .icon{
    	line-height: 90px;
    	font-size: 54px;
        color: $secondary-color;
    }
    h3{
    	border-bottom:3px solid $brand-title-color;
    	border-bottom-style: solid;
    	color:#282828;
    	text-transform: uppercase;
    	font-size: 22px;
        margin-top: 10px;
    	padding-bottom:25px;
    	margin-bottom: 25px;
    }

    p{
    	line-height: 1.8;
    }
}


@media (min-width: $screen-lg-min) {
  .feature-box{
    padding: 0 25px;
  }
}