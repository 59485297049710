/*-----------------------------------------------------------------------------------

Typography

-----------------------------------------------------------------------------------*/


dl, dt, dd, ol, ul, li {
	margin: 0;
	padding: 0;
}

::selection {
	background: $brand-color;
	color: #FFF;
	text-shadow: none;
}

::-moz-selection {
	background: $brand-color; /* Firefox */
	color: #FFF;
	text-shadow: none;
}

::-webkit-selection {
	background: $brand-color; /* Safari */
	color: #FFF;
	text-shadow: none;
}

:active,
:focus { outline: none !important; }

body {
	line-height: $line-height-base;
	color: $body-color;
	font-size: $font-size-base;
	font-family: $font-family-base;
}

a {
	text-decoration: none !important;
	img {
		border: none;
	}
}

i, em{
	font-family: $font-family-italic;
	font-weight: normal;
}

b,strong{
	font-family: $font-family-bold;
	font-weight: normal;
	&i, &em{
		font-family: $font-family-bold-italic;
	}
}

i.icon{
	color: $grey-color;
}

.em{
	color: $secondary-color;
}

/* ----------------------------------------------------------------
	Layout Styles
-----------------------------------------------------------------*/

h1,
h2,
h3,
h4,
h5,
h6 {
	color: $heading-color;
	font-weight: normal;
	line-height: $line-height-base;
	margin: 0 0 40px 0;
	font-family: $heading-font;
	i, em{
		font-family: $heading-font;
		color: $secondary-color;
		font-weight: normal;
		font-style: normal;
	}
	strong, b{
		font-family: $secondary-font-bold;
		i, em{
			font-family: $secondary-font-bold;
			color: $secondary-color;
			font-weight:normal;
			font-style: normal;
		}
	}
}

h1 { font-size: $font-size-h1; }

h2 { font-size: $font-size-h2; }

h3 { font-size: $font-size-h3; }

h4 { font-size: $font-size-h4; }

h5 { font-size: $font-size-h5; }

h6 { font-size: $font-size-h6; }

h4 { font-weight: normal; }

h5,
h6 { 
	font-weight: normal;
	margin-bottom: 20px;  
}

/*
h1,
h2,
h3,
h4,
h5,
h6 {
	> span:not(.nocolor) {
		color: $brand-color;
	}
}*/

.alert{
	h4{margin-bottom:0;}
}

p,
pre,
ul,
ol,
dl,
dd,
blockquote,
address,
table,
fieldset,
form { margin-bottom: 30px; }

small { }



ul{
	list-style-type:none;
	li{
		position:relative;
		padding-left:40px;
		margin-bottom: 20px;
		&:before{
			color: $brand-color;
			content: $ionicon-var-chevron-right;
			position: absolute;
			left:5px;
			top:5px;
			font-size:14px;
			display:inline-block;
			font-family: $ionicons-font-family;
			speak: none;
			font-style: normal;
			font-weight: normal;
			font-variant: normal;
			text-transform: none;
			line-height: 1;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
		}
	}
}

ul.nav,
ul.list-unstyled,
ul.list-inline,
ul.clients-ticker{
	li{
		padding:0;
		margin:0;
		&:before{
			content: '';
			display:none;
		}
	}

}

ul.list-inline > li {
    display: inline-block;
    padding-left: 5px;
    padding-right: 5px;
}

ol{
	padding-left: 30px;
	li{
		margin-bottom: 20px;
		padding-left:10px;
	}
}


blockquote {
	position:relative;
    padding: 30px 60px;
    margin: 40px 0 50px;
    border: 0;
    font-size: 22px;
    text-align:center;
    &:before{
    	content: '';
    	position:absolute;
    	top:0;
    	left:40%;
    	width:20%;
    	border-top:2px dotted #e5e5e5;
    }
    &:after{
    	content: '';
    	position:absolute;
    	bottom:0;
    	left:40%;
    	width:20%;
    	border-top:2px dotted #e5e5e5;
    }
    p{
    	text-align:center;
    }
    p:before{
    	color: $brand-color;
    	font-size: 40px;
		line-height: 0;
	    height: 20px;
	    position: relative;
	    top: 8px;
    	content: "\201C";
    	margin-right: 7px;
    }
    p:after{
    	color: $brand-color;
    	font-size: 40px;
		line-height: 0;
	    height: 20px;
	    position: relative;
	    top: 8px;
    	content: "\201D";
    	margin-left: 7px;
    }
}

q::before {
    content: "\201C";
    margin-right: 7px;
}

q::after {
    content: "\201D";
    margin-left: 7px;
}

q::before,
q::after{
	font-size: 36px;
	line-height: 0;
    height: 20px;
    position: relative;
    top: 12px;
}

.lead-quote{
	font-size:24px;
	line-height: 1.8;
	padding:0;
	margin:0 0 30px 0;
	border: 0;
	text-align: left;
	&:before,
	&:after{
		display:none;
	}
}


.border-title{
	border-bottom: 2px solid #e5e5e5;
	padding-bottom: 30px;
}

.page-title,
.block-title{
	font-size: 32px;
	line-height: 1.4em;
}

h3.page-title,
h3.block-title{
	font-size:28px;
}

.page-title{
	margin-top:-3px;
}

.page-content,
#content .page-content p{
	font-size: 18px;
	line-height: 1.9em;
}

.lead-content,
#content .lead-content p{
	font-size: 18px;
	line-height: 1.9em;
}

.page-title,
.block-title{
	font-family: $secondary-font-light;
	font-weight:normal;
	text-transform: uppercase;
}


.block-title{
	color: $brand-color;
}


@media (min-width: $screen-md-min) {

	.page-title,
	.block-title{
		font-size: 34px;
	}

	h3.page-title,
	h3.block-title{
		font-size: 32px;
	}

	.lead-content,
	#content .lead-content p{
		font-size: 20px;
	}

}


@media (min-width: $screen-lg-min) {

	.page-title,
	.block-title{
		font-size: 42px;
	}

	h3.page-title,
	h3.block-title{
		font-size: 36px;
	}

}