/* Forms */

form{
	margin:0;
}


/* Form elements */

.form-control{
	font-family: $font-family-base;
	background-color: #eeeeee;
	border-color: #eeeeee;
	height: 46px;
	padding: 10px $padding-base-horizontal;
	&:focus {
		@include box-shadow(inset 0 1px 1px rgba(0,0,0,.075));
  	}
}

.help-block{
    font-size: 12px;
    font-family: $secondary-font;
}

@media (min-width: $screen-sm-min) {
	.form-control{
		height: $input-height-base; // Make inputs at least the height of their button counterpart (base line-height + padding + border)
	  	padding: $padding-base-vertical $padding-base-horizontal;
	}
}



/* Search Form */


.search-field{
	border: 2px solid #d2d2d2;
	background:transparent;
	@include box-shadow(none);
	font-size:16px;
	height: 60px;
	padding: 17px 20px;
}

.btn-search{
	padding: 10px 20px 11px;
	font-size: 20px;
	.icon{
		font-size: 32px;
		line-height:1;
	}
}

.page-header {
	.search-form{
		margin-bottom:10px;
	}
	.search-field{
		border: 2px solid $brand-title-color;
		color: $brand-title-color;
		background: rgba(255,255,255,0.1);
		&:focus{
			border: 2px solid $brand-color;
			color: $brand-color;
		}
	}
	::-webkit-input-placeholder {
	   color: $brand-title-color;
	}

	:-moz-placeholder { /* Firefox 18- */
	   color: $brand-title-color;  
	}

	::-moz-placeholder {  /* Firefox 19+ */
	   color: $brand-title-color;  
	}

	:-ms-input-placeholder {  
	   color: $brand-title-color;  
	}
}

/* Contact Form & Comments form */


/* ----------------------------------------------------------------
	Comment Form
-----------------------------------------------------------------*/

#respond,
#respond form {
	margin-bottom: 0;
}

.commentlist {
	li {
		#respond {
			margin: 30px 0 0;
		}
		li {
			#respond {
				margin-left: 30px;
			}
		}
	}
}


.teseo-form,
.comment-form {

	fieldset{
		margin-bottom:40px;
		&.end-fieldset{
			margin-bottom:0;
		}
	}

	legend{
		border:0;
		margin:0;
	}

	p {
		position:relative;
		margin: 20px 0 0 0;
		&:first-child {
			margin-top: 0;
		}
	}

	p.form-submit{
		margin-bottom: 30px;
	}

	span.required{
		pointer-events:none;
		font-size:18px;
		position:absolute;
		right:15px;
		top:26px;
		color: $secondary-color;
		font-family: $secondary-font;
	}

	input[type="text"],
	input[type="email"],
	input[type="url"],
	input[type="tel"],
	input[type="date"],
	textarea {
		background:transparent;
		position:relative;
		font-size:18px;
		margin-bottom: 0;
		padding:18px 30px 16px 30px;
		border:2px solid #d2d2d2;
		width:100%;
	}

	textarea {
		resize: vertical;
		display:block;
		height:200px;
	}

	.field-valid{
		/*input, textarea{
			border-color: $brand-success;
		}*/
		span.required{
			color: $brand-success;
		}
	}

	.field-invalid{
		input, textarea{
			border-color: $brand-danger;
		}
		span.required{
			color: $brand-danger;
		}
	}

	.wpcf7-not-valid-tip,
	span.invalid{
		position:relative;
		top:-2px;
		display:block;
		background-color: $brand-danger;
		color: #fff;
		padding:5px 35px;
		font-size:12px;
		font-family: $secondary-font;
		&:before{
	    	content: '';
	    	border-right: 4px solid transparent;
		    border-left: 4px solid transparent;
		    border-bottom: 4px solid $brand-danger;
		    top: 0;
		    left: 30px;
		    margin-right: -4px;
		    margin-top: -4px;
		    position: absolute;
	    }
	}

	#accept-this-error{
		margin-top:10px;
	}

	p.form-terms{
		font-size: 14px;
		.wpcf7-form-control-wrap{
			margin-right:5px;
		}
	}

}

.contact-form{
	.form-radio-group{
		margin: 20px 0 40px;
	}
	.wpcf7-list-item{
		margin-left:0;
		margin-bottom:20px;
		display:block;
		.radio{
			margin-right:7px;
			margin-top: -3px;
		}
	}
	p.form-submit{
		margin-bottom:0;
	}

	div.wpcf7-response-output {
	    margin: 10px 0 30px;
	    padding: 0.5em 1em;
	}

	div.wpcf7-mail-sent-ng {
	    border: 2px solid $brand-danger;
	    color: $brand-danger;
	}

}

.widget .teseo-form{
	.form-control{
		height:32px;
	}

	input[type="text"],
	input[type="email"],
	input[type="url"],
	input[type="tel"],
	input[type="date"],
	textarea {
		font-size:16px;
		padding:10px 20px 10px 20px;
	}

	span.required{
		top:16px;
	}

	.btn{
		padding: 10px;
	}

	p.form-submit{
		margin-bottom: 0;
	}

	fieldset{
		margin-bottom:0;
	}

}

@media (min-width: $screen-md-min) {
	.contact-form{

		p.form-submit{
			&:after{
				top:22px;
			}
		}

	}

	#respond form{
		margin-right:20%;
	}

}