
.front-page { 
    margin-top: -50px; 

    div.notes {
      
      .inside {
       background-color: lighten($grey-color, 35%); padding: 10px; 
	     img { width: 100%; height: auto; @extend .img-responsive };
	     h2 { font-size: 1.3em; margin: 0px; padding: 0px; text-align: left; color: $brand-color; };
	     p { font-size: 0.9em; display:block; margin: 0px; padding: 0px; text-align: left; color: ligthen($grey-color, 15%); };
       }

   } 

   .portfolio {
      .inside { padding: 0px 0px 10px 0px; }

   }
 };



.notes {
  width: 100%; @extend .list-unstyled; text-align:center; 
  li { 
     display: inline-block; 
     .note {  padding: 20px 20px 20px 20px; margin: 10px; background-color: lighten($grey-color,35%); }
     img { width: 100%; height:auto; @extend .img-responsive; }
   h2  { font-size: 1.2em; margin: -15px 0px 0px 0px; font-weight: bold; color: $brand-dark-color; text-align: left; }
   p   { font-size: 0.8em; text-align: left; color: darken($grey-color,10%); margin: 0px 0px 0px 0px; padding: 0px 0px 10px 0px; }
  }
}

.logos {
  width: 100%; text-align: center; @extend .list-unstyled; padding: 20px 0px 0px 0px; 
  div, li { 
    display: inline-block; 
    img { width: 150px; height: auto; margin: 0px 5px 8px 5px; }
   }  
}

.horizontal-list {
  width: 100%; text-align: center; @extend .list-unstyled; 
  div, li {
    display: inline-block;
  }
}


hr { 
  @extend .clearfix;  
  clear: both; 
}


.custom_titles {
   margin-top: -50px;

}

