
.sidebar{

  .widget{
    @include clearfix();
    margin-top: 25px;
    border-bottom: 1px solid #ddd;
    padding: 15px 25px;
    background: #f8f8f8;
    @include border-radius(2px);
    h3{
      zoom: 1;
      font-size: 16px;
      margin: -15px -25px 15px -25px;
      padding: 15px 25px 10px;
      border-bottom: 1px solid #fff;
      background: #E2EBFB;
      border-radius: 4px 4px 0 0;
      text-transform: uppercase;
      color: #7184AB;
    }
    > ul{
      padding-left:0;
      margin:0;
      > li{
        list-style:none;
        padding: 0;
        margin:5px 0;
        &:before{
          content: '';
          display:none;
        }
      }
    }
  }

  .widget_search{
    border:0;
    padding: 0;
    border-radius: 0;
    margin-top:0;
    .search-form{
      margin:0;
    }
    .col-sm-offset-4,
    .col-sm-8,
    .col-md-offset-6,
    .col-md-6{
      margin:0;
      width: 100%;
    }

  }


}


@media (min-width: $screen-md-min) {
  .sidebar{
    padding-left: 30px;
  }
}
