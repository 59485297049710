/* show room */ 

.showroom-item {
    width: 100%; 
    padding: 10px;
    position: relative; 
    float: left; 
}

.showroom-image a, .showroom-image img {
    display: block;
    width: 100%;
    height:auto;
    position: relative;
    z-index: 10;
}

.showroom-item h3 {
    margin: 0;
    padding: 0;
    font-size: 20px;
    overflow:hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
}

@media (min-width: $screen-xs-min) {
   .showroom-item{
        width:50%;
   }
}

@media (min-width: $screen-md-min) {
   .showroom-item{
        width:33%;
   }
}



/*-----------------------------------------------------------------------------------

Portfolio Featured

-----------------------------------------------------------------------------------*/

.portfolio {
    position: relative;
    margin: 0 -16px -16px 0;
}


.portfolio-item {
    position: relative;
    float: left;
    width:100%;
    padding: 10px;
}

@media (min-width: $screen-xs-min) {
   .portfolio-item{
        width:50%;
   }
}

@media (min-width: $screen-md-min) {
   .portfolio-item{
        width:33%;
   }
}

.portfolio-item .portfolio-image {
    position: relative;
    overflow: hidden;
}


.portfolio-item .portfolio-image,
.portfolio-item .portfolio-image a,
.portfolio-item .portfolio-image img {
    display: block;
    width: 100%;
    height: auto;
}

.portfolio-desc {
    z-index: 3;
    padding: 15px 0 10px;
}

.portfolio-desc h3 {
    margin: 0;
    padding: 0;
    font-size: 20px;
    overflow:hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
}

.portfolio-desc span {
    display: block;
    margin-top: 3px;
    color: #888;
    overflow:hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
}

.portfolio-desc span a { color: #999; }

.portfolio-desc span a:hover { color: $brand-title-color; }


/* Portfolio - Overlay
-----------------------------------------------------------------*/

.portfolio-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 2;
    text-align: center;
    background-color: rgba(0,0,0,0.3);
}


body:not(.device-touch):not(.device-xs):not(.device-xxs) .portfolio-overlay {
    -webkit-transition: opacity .4s ease-in-out;
    -o-transition: opacity .4s ease-in-out;
    transition: opacity .4s ease-in-out;
}

.portfolio-item:hover .portfolio-overlay { opacity: 1; }

.portfolio-overlay a {
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: #F5F5F5;
    width: 40px !important;
    height: 40px !important;
    margin: -28px 0 0 -44px;
    font-size: 18px;
    line-height: 40px;
    text-align: center;
    color: $brand-title-color;
    border-radius: 50%;
    -webkit-backface-visibility: hidden;
    .icon{
        color: inherit;
    }
    &:hover,
    &:focus{
        background: $brand-title-bg;
        color: $brand-color;
    }
}


body:not(.device-touch):not(.device-xs):not(.device-xxs) .portfolio-overlay a {
    -webkit-transition: color .2s linear, background-color .2s linear, margin-top .2s linear, opacity .2s linear;
    -o-transition: color .2s linear, background-color .2s linear, margin-top .2s linear, opacity .2s linear;
    transition: color .2s linear, background-color .2s linear, margin-top .2s linear, opacity .2s linear;
}

.portfolio-overlay a.left-icon {  }

.portfolio-overlay a.right-icon {
    left: auto;
    right: 50%;
    margin-left: 0;
    margin-right: -44px;
}

.portfolio-overlay a.center-icon {
    display: block;
    margin: -20px 0 0 -20px;
    opacity: 0;
}

.portfolio-item:hover a.center-icon{ opacity: 1; }

.portfolio-item:hover .portfolio-overlay a.left-icon,
.portfolio-item:hover .portfolio-overlay a.right-icon { margin-top: -18px; }
